.greenCircle {
  font-weight: bold;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  stroke-width: 0%;
  stroke: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: inset 0 0 10px #0c7521a6,
    /* inner white */ inset 10px 0 20px rgb(20, 171, 27),
    /* inner left magenta short */ inset -10px 0 20px #0ff,
    /* inner right cyan short */ inset 10px 0 150px rgb(20, 171, 27),
    /* inner left magenta broad */ inset -10px 0 150px #0ff,
    /* inner right cyan broad */ 0 0 10px #0c7521a6,
    /* outer white */ -5px 0 20px rgb(20, 171, 27),
    /* outer left magenta */ 5px 0 20px #0ff; /* outer right cyan */
  transition: transform 0.3s ease-in-out;
  animation: gentleMotion 3s ease-in-out infinite;
  animation-delay: calc(1s * random()); /* Random delay up to 1s */
  animation-duration: calc(
    2s + 1s * random()
  ); /* Random duration between 2s and 3s */
}

@keyframes gentleMotion {
  0%,
  100% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-10px) translateX(-10px);
  }
  50% {
    transform: translateY(0) translateX(10px);
  }
  75% {
    transform: translateY(10px) translateX(-10px);
  }
}
.redCircle {
    font-weight: bold;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: inset 0 0 25px #6f0c0c,
      /* inner white */ inset 10px 0 20px rgb(171, 20, 20),
      /* inner left magenta short */ inset -10px 0 20px rgb(207, 178, 19),
      /* inner right cyan short */ inset 10px 0 150px rgb(171, 20, 20),
      /* inner left magenta broad */ inset -10px 0 150px rgb(255, 217, 0),
      /* inner right cyan broad */ 0 0 25px #6f0c0c,
      /* outer white */ -5px 0 20px rgb(171, 20, 20),
      /* outer left magenta */ 5px 0 20px rgb(255, 217, 0); /* outer right cyan */
  
    /* Animation for flickering effect on box-shadow */
    animation: flickerShadowAnimation 1s infinite alternate;
  }
  
  @keyframes flickerShadowAnimation {
    0% {
      box-shadow: inset 0 0 25px #6f0c0c,
        /* inner white */ inset 10px 0 20px rgb(171, 20, 20),
        /* inner left magenta short */ inset -10px 0 20px rgb(207, 88, 19),
        /* inner right cyan short */ inset 10px 0 150px rgb(171, 20, 20),
        /* inner left magenta broad */ inset -10px 0 150px rgb(207, 88, 19),
        /* inner right cyan broad */ 0 0 25px #6f0c0c,
        /* outer white */ -5px 0 20px rgb(171, 20, 20),
        /* outer left magenta */ 5px 0 20px rgb(207, 88, 19); /* outer right cyan */
    }
    100% {
      box-shadow: inset 0 0 25px #6f0c0c,
        /* inner white */ inset 10px 0 20px rgba(171, 20, 20, 0.274),
        /* inner left magenta short */ inset -10px 0 20px rgb(200, 24, 24),
        /* inner right cyan short */ inset 10px 0 150px rgba(171, 20, 20, 0.274),
        /* inner left magenta broad */ inset -10px 0 150px rgba(171, 20, 20, 1),
        /* inner right cyan broad */ 0 0 25px #6f0c0c,
        /* outer white */ -5px 0 20px rgba(171, 20, 20, 0.274),
        /* outer left magenta */ 5px 0 20px rgba(171, 20, 20, 1); /* outer right cyan */
    }
  }
  